// fm的app包含修改信息
<template>
  <el-container>
    <div id="app">
      <div>
        <div v-show="window_width < 768" style="height: 12vh">
          <div align="center" style="
              position: fixed;
              width: 100%;
              z-index: 20;
              background-color: #3C3C3C;
            ">
            <img src="/static/CCKS_logo_title.svg" style="height: 12vh" />
          </div>
        </div>
        <el-menu v-show="window_width > 768" :class="[this.$store.state.isCollapse ? 'header_height_new' : 'menu']" router
          mode="horizontal" :background-color="color_black" :text-color="color_write" active-text-color="#e2a253" style="
            font-family: SimSun, Times, serif;
            text-align: center;
            padding-top: 0.2vh;
            height: 12vh;
            box-shadow: 0px 1px 3px #aaaaaa;
            border-bottom: 0.5vh solid #e2a253;
          " width="100%">
          <el-menu-item :class="[
            this.$store.state.isCollapse ? 'noneclass_hidden' : 'noneclass',
          ]" index="/" style="color: #e2a253">
            <img style="height: 18vh; width: 15vw; object-fit: contain; margin-top: -4.5vh;" src="/static/CCKS_logo_title.svg"
              @click="refresh" />
          </el-menu-item>

          <!-- 影人年谱下拉菜单 -->
          <el-submenu index="1">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ people }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">Filmmakers' Nianpu</p>
              </div>
            </template>
            <el-menu-item index="/Map" @click="handlecloud('border', '照片墙')">
              {{ people_map }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Filmmakers' Map</p>
            </el-menu-item>
            <el-menu-item index="/directors">
              {{ people_china }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Chinese Filmmakers</p>
            </el-menu-item>
            <el-menu-item index="/ChronicleList">
              {{ chronicleList }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Nianpu List</p>
            </el-menu-item>
            <el-menu-item index="/Chronicle">
              {{ ChronicleAnalysis }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Visual Analysis</p>
            </el-menu-item>
            <el-menu-item index="/EssaysIndex">
              {{ essay_peo }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Filmmakers' Literature</p>
            </el-menu-item>
          </el-submenu>
          <!-- 电影计量下拉菜单 -->
          <el-submenu index="2">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ video_title }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">CCKS-Cinemetrics</p>
              </div>
            </template>
            <el-menu-item index="/video_index">
              {{ movie_classic }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Classic Films</p>
            </el-menu-item>
            <el-menu-item index="/lengthTable">
              {{ WorldASLdata }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">ASL of World Films</p>
            </el-menu-item>
            <el-menu-item index="/Filmanalysis?moviename=神女">
              {{ filmanalysis }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Film Analysis</p>
            </el-menu-item>
            <el-menu-item index="/filmVisualization">
              {{ filmVisualization }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Film Visualization</p>
            </el-menu-item>
            <el-menu-item index="/company">{{ company }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Film Companies</p>
            </el-menu-item>
          </el-submenu>
          <!--数字生命下拉菜单-->
          <el-submenu index="3">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ knowledgeGene }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">Digital Life</p>
              </div>
            </template>
            <el-menu-item index="/force" @click="handlecloud('force', '关系图')">
              {{ force }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Social Network</p>
            </el-menu-item>
            <el-menu-item index="/lollipop" @click="handlecloud('lollipop')">
              {{ ASLdata }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">ASL of Chinese Films</p>
            </el-menu-item>
            <el-menu-item index="/line" @click="handlecloud('line', '影人历史图')">
              {{ picFigure }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Filmmakers' Collections</p>
            </el-menu-item>
            <el-menu-item index="/video" @click="handlecloud('video', '年代影像图')">
              {{ picFilm }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Filmmakers' Works</p>
            </el-menu-item>
            <el-menu-item index="/DigitalLife">
              {{ digital }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Digital Life</p>
            </el-menu-item>
          </el-submenu>
          <!--电影百科下拉菜单-->
          <el-submenu index="4">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ encyclopedia }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">Film Encyclopedia</p>
              </div>
            </template>
            <el-menu-item index="/Montage">
              {{ Montage }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Tag Collection</p>
            </el-menu-item>
            <el-menu-item index="/occupation">
              {{ occupation }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Career Navigation</p>
            </el-menu-item>
            <el-menu-item index="/AnnualEvent">
              {{ AnnualEvent }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Film Chronology</p>
            </el-menu-item>
            <el-menu-item index="/postSearch">
              {{ postSearch }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Tag Search</p>
            </el-menu-item>
            <el-menu-item index="/titleSearch">
              {{ titleSearch }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Title Search</p>
            </el-menu-item>
          </el-submenu>
          <!-- 藏品索引下拉菜单 -->
          <el-submenu index="5">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ essay_title }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">Collection Catalog</p>
              </div>
            </template>
            <el-menu-item index="/Content_book">
              {{ essay_chi }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Works</p>
            </el-menu-item>
            <el-menu-item index="/figurearticleIndex">
              {{ figurearticles }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Papers</p>
            </el-menu-item>
            <el-menu-item index="/articleIndex">
              {{ literature }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Early Periodicals</p>
            </el-menu-item>
            <el-menu-item index="/Content_journal">
              {{ periodical }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Journals</p>
            </el-menu-item>
            <el-menu-item index="/Content_references">
              {{ references }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Related Achievements</p>
            </el-menu-item>
            <el-menu-item index="/Content_website">
              {{ essay_website }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Related Platforms</p>
            </el-menu-item>
            <el-menu-item index="/Content_letters">
              {{ Letters }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Collection Catalog</p>
            </el-menu-item>
          </el-submenu>
          <!-- 关于我们下拉菜单 -->
          <el-submenu index="6">
            <template slot="title">
              <div style="margin-top: -1vh;">
                {{ about }}
                <p style="line-height: 0; margin: 0;font-size: 80%;">About Us</p>
              </div>
            </template>
            <el-menu-item index="/introduction">
              {{ introduction }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Project Introduction</p>
            </el-menu-item>
            <el-menu-item index="/CompilationGuide">
              {{ editGuidance }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Editing Guidelines</p>
            </el-menu-item>
            <el-menu-item index="/dialog">
              {{ programDialog }}
              <p style="line-height: 0; margin: 0;font-size: 80%;">Project Logs</p>
            </el-menu-item>
          </el-submenu>

          <!-- 登陆成功 -->
          <el-row class="reandlog" justify-content="flex-end" style="padding: 1.2vh 0.5vw" height="8vh"
            align="self-center" v-if="this.$store.state.login_state && !this.$store.state.isCollapse
              ">
            <el-dropdown trigger="click" placement="bottom">
              <span class="mouse_over_user" style="
                  margin-right: 1vw;
                  color: #b8860b;
                  line-height: 8vh;
                  font-size: 1vw;
                  font-weight: bold;
                " align="center">{{ this.$store.state.user_name }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changeVisible = true">更改密码</el-dropdown-item>
                <el-dropdown-item @click.native="change_information_visible = true">修改信息</el-dropdown-item>
                <router-link class="inlineBlock" to="/PersonalPage" style="text-decoration: none">
                  <el-dropdown-item>个人主页</el-dropdown-item>
                </router-link>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button size="medium" align="center" @click="dialogVisible = true" type="warning" round plain
              style="margin-top: 2.4vh; width: 6vw">登出
            </el-button>
            <el-dropdown trigger="click" placement="bottom" v-if="this.$store.state.is_administrator">
              <span class="mouse_over_user" style="
                  margin-right: 5px;
                  margin-left: 1vw;
                  color: #b8860b;
                  line-height: 60px;
                  font-size: 1vw;
                  font-weight: bold;
                " align="center">管理员
              </span>
              <el-dropdown-menu slot="dropdown">
                <router-link class="inlineBlock" to="/registerAuthorize" style="text-decoration: none">
                  <el-dropdown-item>{{ registerAuthorize }}</el-dropdown-item>
                </router-link>
                <router-link class="inlineBlock" to="/Statistics" style="text-decoration: none">
                  <el-dropdown-item>统计</el-dropdown-item>
                </router-link>
              </el-dropdown-menu>
            </el-dropdown>

            <el-menu-item v-show="this.$store.state.isCollapse && !this.$store.state.login_state
              " index="/Login">登录</el-menu-item>
            <el-menu-item v-show="this.$store.state.isCollapse && this.$store.state.login_state
              " @click="dialogVisible = true">登出</el-menu-item>
          </el-row>
          <!-- 登录与注册按钮 -->
          <el-row :class="[
            this.$store.state.isCollapse ? 'reandlog_hidden' : 'reandlog',
          ]" justify="end" style="padding: 0.7vh 0vw" v-else>
            <router-link to="/Login">
              <el-button style="margin-right: 0.5vw; margin-top: 2.4vh; width: 6vw" type="warning" plain
                icon="el-icon-user-solid" round>登录</el-button>
            </router-link>

            <router-link to="/Register">
              <el-button type="info" style="margin-top: 2.4vh; width: 6vw" plain icon="el-icon-user" round>注册</el-button>
            </router-link>
          </el-row>
          <div style="position: relative; display: inline-block; float: right;">
            <!-- 搜索图标 -->
            <el-menu-item style="top: 2.5vh; line-height: 45px;">
              <div @click="toggleSearch" style="cursor: pointer;">
                <i class="el-icon-search" style="font-size: 1.5vw; color: #F0EBE1; font-weight: bold;"></i>
              </div>
            </el-menu-item>

            <!-- 搜索框 -->
            <el-autocomplete index="2" v-if="isSearchVisible" :class="[
              this.$store.state.isCollapse ? 'input_style_phone' : 'input_style',
            ]" v-model="search_content" :fetch-suggestions="query_search" placeholder="请输入内容" @select="handle_select"
              @keyup.enter.native="search_click">
              <el-select v-model="select" slot="prepend" placeholder="选择" class="select_style"
                @change="serach_item_change" style="width: 5vw;marigin-left:1vw">
                <el-option label="影人" value="1"></el-option>
                <el-option label="影片" value="2"></el-option>
                <el-option label="文章" value="3"></el-option>
                <el-option label="文献" value="4"></el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" class="serach_button_style"
                @click="search_click"></el-button>
            </el-autocomplete>
          </div>

          <!-- <el-row :class="'reandlog_icons'" v-if="this.$store.state.isvis != 'home'"
            style="margin-top: 2.9vh; margin-left: 1vw; width: 18.5vw">
            <div class="top_icon_style el-dropdown-link" style="display: inline-block"
              @click="handlecloud('force', '关系图')">
              <el-tooltip class="item" effect="dark" content="关系图" placement="bottom">
                <i class="el-icon-connection"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block"
              @click="handlecloud('cloud', '词云图')">
              <el-tooltip class="item" effect="dark" content="词云图" placement="bottom">
                <i class="el-icon-cloudy"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block"
              @click="handlecloud('border', '照片墙')">
              <el-tooltip class="item" effect="dark" content="照片墙" placement="bottom">
                <i class="el-icon-picture-outline-round"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block"
              @click="handlecloud('line', '影人历史图')">
              <el-tooltip class="item" effect="dark" content="影人历史图" placement="bottom">
                <i class="el-icon-time"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block"
              @click="handlecloud('video', '年代影像图')">
              <el-tooltip class="item" effect="dark" content="年代影像图" placement="bottom">
                <i class="el-icon-orange"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block" @click="handlecloud('lollipop')">
              <el-tooltip class="item" effect="dark" content="中国电影ASL" placement="bottom">
                <i class="el-icon-lollipop"></i>
              </el-tooltip>
            </div>

            <div class="top_icon_style el-dropdown-link" style="display: inline-block; margin-right: 15px"
              @click="handlecloud('hist')">
              <el-tooltip class="item" effect="dark" content="平均镜头长度" placement="bottom">
                <i class="el-icon-coin"></i>
              </el-tooltip>
            </div>
          </el-row> -->
        </el-menu>
      </div>

      <div>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
      <!-- 登出提示 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定要登出吗...</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="warning" @click="logout_btn">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改密码 -->
      <el-dialog title="密码修改" :visible.sync="changeVisible" width="38 %">
        <el-form ref="change_pw_form" :model="change_pw" :rules="rules" label-width="80px">
          <el-form-item label="原始密码" prop="password">
            <el-input type="password" placeholder="请输入原密码" v-model="change_pw.password" />
          </el-form-item>
          <el-form-item label="新的密码" prop="new_password">
            <el-input type="password" placeholder="请输入新密码" v-model="change_pw.new_password" />
          </el-form-item>
          <el-form-item label="确认密码" prop="new_password_again">
            <el-input type="password" placeholder="请再次入新密码" v-model="change_pw.new_password_again" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="changeVisible = false">取 消</el-button>
          <el-button type="warning" @click="onSubmit('change_pw_form')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 密码修改确认 -->
      <el-dialog title="提示" :visible.sync="change_pw_makesure" width="30%">
        <span>确定要修改吗...</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="change_pw_makesure = false">取 消</el-button>
          <el-button type="warning" @click="change_pw_btn">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改个人信息弹窗 -->
      <el-dialog title="个人信息修改" :visible.sync="change_information_visible" width="40%">
        <el-form ref="change_information_form" :model="change_infor" label-width="6vw">
          <el-form-item label="用户名">{{
            this.$store.state.user_name
          }}</el-form-item>
          <el-form-item label="真实姓名" prop="true_name">
            <el-input placeholder="请输入真实姓名" v-model="change_infor.true_name" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input placeholder="请输入邮箱地址" v-model="change_infor.email" />
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="change_infor.sex">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
              <el-radio label="其他"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="电话号码" prop="phone">
            <el-input placeholder="请输入电话号码" v-model="change_infor.phone" />
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input placeholder="请输入身份证号码" v-model="change_infor.idcard" />
          </el-form-item>
          <el-form-item label="证书号" prop="idcertificates">
            <el-input placeholder="请输入证书号码" v-model="change_infor.idcertificates" />
          </el-form-item>
          <el-form-item v-if="this.$store.state.is_summerschoolstudent" label="证书照片" prop="photo">
            <el-upload :limit="1" :auto-upload="false" :on-remove="handleRemove" :on-change="handleChange"
              :file-list="fileList" action="#" class="avatar-uploader" list-type="picture-card"
              accept="image/jpg,image/jpeg,image/png">
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="el-upload__tip">jpg、jpeg、png格式，大小2M以内</div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="change_information_visible = false">取 消</el-button>
          <el-button type="warning" @click="onSubmitInfor('change_information_form')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 个人信息修改确认弹窗 -->
      <el-dialog title="提示" :visible.sync="change_information_makesure" width="30%">
        <span>确定要修改吗...</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="change_information_makesure = false">取 消</el-button>
          <el-button type="warning" @click="change_information_click">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <el-footer style="padding: 0; width: 100vw; position: relative; overflow: visible;">
      <div style="background-color: #DAD3CB;">
        <div :class="[
          this.$store.state.isCollapse
            ? 'foot_middle_collapse'
            : 'foot_middle',
        ]">
          <div>
            <a href="https://www.pku.edu.cn/" target="_Blank">
              <img style="width: 14vw; margin-right: 1vw; margin-top: 1vw;" src="/static/pku.png" />
            </a>
          </div>
          <div>
            <a href="http://www.cuc.edu.cn/" target="_Blank">
              <img style="width: 14vw; margin-right: 1vw; margin-top: 1vw;" src="/static/cuc.png" />
            </a>
          </div>
          <div>
            <div :class="[
              this.$store.state.isCollapse ? 'text_collapse' : 'text_style',
            ]">
              {{ copyright2 }}
            </div>
            <div :class="[
              this.$store.state.isCollapse ? 'text_collapse' : 'text_style',
            ]">
              {{ copyright }}
            </div>
            <div :class="[
              this.$store.state.isCollapse ? 'text_collapse' : 'text_style',
            ]">
              {{ copyright3 }}
            </div>
          </div>
          <div style="margin-top: 1vw;">
            <img v-show="window_width > 768" class="sao_style" src="/static/CCKS-WX.jpg" style="
            border-radius: 3px;
            height: 5vw;
            box-shadow: 3px 3px 10px #aaaaaa;
          " />
            <img v-show="window_width < 768" class="sao_style" src="/static/CCKS-WX.jpg" style="
            border-radius: 3px;
            width: 2em;
            box-shadow: 3px 3px 10px #aaaaaa;
          " />
          </div>
          <div style="margin-top: 1vw;">
            <img v-show="window_width > 768" class="sao_style" src="/static/ruyijuben.png" style="
            border-radius: 3px;
            margin-left: 10px;
            height: 5vw;
            box-shadow: 3px 3px 10px #aaaaaa;
          " />
          </div>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import pkg from "../package.json";
import service from "./utils/request";
export default {
  name: "HelloWorld",
  data() {
    //表单验证
    var validate_oldpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else {
        callback();
      }
    };
    var validate_newpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    var validate_newpass_again = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.change_pw.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      window_width: window.screen.width,
      encyclopedia: "电影百科",
      copyright: pkg.description,
      copyright2: pkg.description2,
      copyright3: pkg.description3,
      datatime: "日期",
      something: "事件",
      homepage: "中国特色电影知识体系研究",
      years: "年谱",
      people: "影人年谱",
      people_map: "影人图谱",
      people_china: "中国影人",
      people_world: "世界影人",
      video_title: "电影计量",
      video_brief: "影片题录",
      video: "影像视频",
      movie_classic: "经典电影",
      WorldASLdata: "世界电影ASL",
      filmanalysis: "电影拉片",
      filmVisualization: "电影可视化",
      company: "制片公司",
      essay_title: "藏品索引",
      essay_peo: "影人文论",
      comments: "评论",
      introduction: "项目简介",
      knowledgeGene: "数字生命",
      editGuidance: "编纂指南",
      about: "关于我们",
      programDialog: "项目日志",
      force: "社交网络",
      ASLdata: "华语ASL",
      picFigure: "影人星丛",
      picFilm: "影人作品",
      figurearticles: "论  文",
      literature: "早期报刊",
      references: "相关成果",
      essay_website: "相关平台",
      essay_chi: "著  作",
      periodical: "期  刊",
      chronicle: "影人年谱",
      HKmovie: "香港电影",
      postSearch: "标签搜索",
      titleSearch: "标题搜索",
      occupation: "职业导航",
      Francedirector: "法国电影",
      AnnualEvent: "电影年表",
      Montage: "标签词云",
      ChronicleAnalysis: "可视分析",
      chronicleList: "年谱列表",
      registerAuthorize: "注册验证", 
      Letters:"藏品索引",
      digital:"数字生命",
      events: [],
      path: "https://api.movie.yingshinet.com",
      figures: [],
      figure_names: [],
      figures_serach: [],
      figure_count: 0,
      chronicles: [],
      chronicle_count: 0,
      movies: "",
      monthdate: "",
      month: "",
      dialog: false,
      table: false,
      loading: false,
      event_content: "",
      formLabelWidth: "40px",
      timer: null,
      date_value: "",
      date_value_two: "",
      count: "",
      contact_url: "/static/contact.png",
      weixin_url: "/static/guangying.png",
      color_old: "#B8860B",
      color_main: "#E2A253",
      color_black: "#323232",
      color_write: "#F0EBE1",
      color_gray: "",
      is_login: "",
      dialogVisible: false,
      changeVisible: false,
      change_pw_makesure: false,
      search_content: "",
      select: "1",
      movies: [],
      movie_count: 0,
      essays: [],
      essay_count: 0,
      articles: [],
      literature_count: 0, //文献计数
      article_count: 0, //著作计数
      //表单
      change_pw: {
        password: "",
        new_password: "",
        new_password_again: "",
      },
      //表单规则
      rules: {
        password: [
          { required: true, validator: validate_oldpass, trigger: "change" },
        ],
        new_password: [
          { required: true, validator: validate_newpass, trigger: "change" },
        ],
        new_password_again: [
          {
            required: true,
            validator: validate_newpass_again,
            trigger: "change",
          },
        ],
      },
      //修改个人信息
      change_information_visible: false,
      change_information_makesure: false,
      change_infor: {
        true_name: "",
        email: "",
        sex: "",
        phone: "",
        idcard: "",
        idcertificates: "",
      },
      user_id: "",
      //上传照片
      url: "",
      onChangeImgUrl: "",
      image_file: [],
      fileList: [],
      isrightshape: false,
      isSearchVisible: false
    };
  },
  //解决el-upload的picture-card样式问题
  watch: {
    onChangeImgUrl: {
      handler(newName) {
        var aa = document.querySelector(".el-upload--picture-card");
        if (newName) {
          aa.style.display = "none";
        } else {
          setTimeout(() => {
            aa.style.display = "inline-block";
          }, 1000);
        }
      },
    },
  },
  methods: {
    refresh() {
      let isvis = (this.$store.state.isvis == "home") ? "homeold" : "home";
      this.$store.commit("handlevis", isvis);
      this.$router.push("/");
      // this.$router.go(0);
      this.$forceUpdate();
    },
    ToRegisterAuthorize() {
      this.router.push("/RegisterAuthorize");
    },
    ToStatistics() {
      this.router.push("/Statistics");
    },
    handlecloud(isvis, name) {
      this.viswhat = name;
      this.$store.commit("handlevis", isvis);
      this.$router.push("/");
    },
    logout_btn() {
      this.dialogVisible = false;
      service({ url: "/logout", method: "get" })
        .then((response) => {
          this.$store.commit("change_state_out");
          this.$message({
            message: "登出成功！",
            type: "success",
          });
          this.$router.push("/");
          this.$store.commit("set_not_administrator");
          this.$store.commit("set_not_summerschoolstudent");
          window.sessionStorage.setItem("auth", 0);
          window.sessionStorage.setItem("summerschool", 0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSubmit(change_pw_form) {
      this.$refs[change_pw_form].validate((valid) => {
        if (valid) {
          this.change_pw_makesure = true;
        }
      });
    },
    change_pw_btn() {
      service({
        url: "/changepsw",
        method: "post",
        data: {
          old_password: this.change_pw.password,
          new_password: this.change_pw.new_password,
        },
      })
        .then((response) => {
          if (response.data.msg == "change password success") {
            (this.changeVisible = false),
              (this.change_pw_makesure = false),
              this.$message({
                message: "修改成功",
                type: "success",
              });
          } else {
            (this.changeVisible = false),
              (this.change_pw_makesure = false),
              this.$message({
                message: "修改失败",
                type: "warning",
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 提交修改个人信息表单
    onSubmitInfor(change_information_form) {
      this.$refs[change_information_form].validate((valid) => {
        if (valid) {
          this.change_information_makesure = true;
        }
      });
    },
    // 修改个人信息
    change_information_click() {
      console.log("id: " + this.user_id);
      console.log(this.change_infor.photo);
      service({
        url: "/changeinfo",
        method: "post",
        data: {
          id: this.user_id,
          true_name: this.change_infor.true_name,
          email: this.change_infor.email,
          sex: this.change_infor.sex,
          phone: this.change_infor.phone,
          photo: this.change_infor.photo,
          isrightshape: this.isrightshape,
          idcard: this.change_infor.idcard,
          idcertificates: this.change_infor.idcertificates,
        },
      })
        .then((response) => {
          this.change_information_visible = false;
          this.change_information_makesure = false;
          if (response.data.msg == "change error") {
            this.$message({
              message: "修改失败",
              type: "warning",
            });
          } else if (
            response.data.msg == "format error" &&
            this.$store.state.is_summerschoolstudent
          ) {
            this.$message({
              message: "照片格式错误",
              type: "warning",
            });
          } else if (
            response.data.msg == "format error" &&
            !this.$store.state.is_summerschoolstudent
          ) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else if (response.data.msg == "Success") {
            if (this.image_file.length == 1) {
              let file = this.image_file[0].raw;
              let pic_param = new FormData(); //form对象
              let usernanme = this.$store.state.user_name;
              let config = {
                headers: { "Content-Type": "multipart/form-data" },
              };
              pic_param.append("file", file, usernanme + ".jpg");
              this.axios
                .post(
                  "https://api.movie.yingshinet.com/changecertificate",
                  pic_param,
                  config
                )
                .then((response) => {
                  if (response.data.msg == "success") {
                    this.$message({
                      message: "证书照片上传成功！",
                      type: "success",
                    });
                    location.reload();
                  } else {
                    this.$message({
                      message: "证书照片好像出了点问题",
                      type: "warning",
                    });
                  }
                });
            }
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleChange(file, fileList) {
      this.onChangeImgUrl = file.url; //解决el-upload样式问题
      console.log(file);
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过 2M!");
        return false;
      } else if (!isJPG) {
        this.$message.warning("上传图片格式只能是jpg或png");
        return false;
      } else {
        this.imgchecked(file).then((data) => {
          //data返回值即为判断结果。
          if (data && isJPG && isLt2M) {
            this.isrightshape = true;
          } else {
            this.isrightshape = false;
            return false;
          }
        });
      }
      this.image_file.push(file);
    },
    handleRemove(file, fileList) {
      this.onChangeImgUrl = "";
    },
    // 限制图片大小和尺寸
    imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = () => {
          let img = new Image();
          img.src = reader.result;
          img.onload = () => {
            if (
              Math.floor((img.width / img.height) * 100) / 100 > 0.75 ||
              Math.floor((img.width / img.height) * 100) / 100 < 0.65
            ) {
              this.$message.warning(
                `需上传图片宽高比例为3：4,当前文件${img.width}×${img.height}`
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
        };
      });
    },
    // select option回调方法
    serach_item_change(value) {
      this.search_content = "";
      switch (value) {
        // 影人搜索
        case "1":
          this.serach_option = this.figure_names;
          break;
        case "2":
          // 影片搜索
          this.serach_option = this.movies;
          break;
        case "3":
          // 文章搜索
          this.serach_option = this.essays;
          break;
        case "4":
          // 文章搜索
          this.serach_option = this.articles;
          break;
        default:
      }
    },
    // 搜索点击
    search_click() {
      switch (this.select) {
        case "1":
          // 影人搜索
          if (this.search_content.trim()) {
            this.figures_serach = [];
            for (var i = 0; i < this.figures.length; i++) {
              if (
                this.figures[i].f_name.includes(
                  this.search_content.trim().toLowerCase()
                )
              ) {
                this.figures_serach.push(this.figures[i]);
              }
            }
          } else {
            this.figures_serach = this.figures;
          }
          break;
        case "2":
          // 影片搜索
          break;
        case "3":
          // 文章搜索
          break;
        case "4":
          // 文献搜索
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    // 匹配搜索建议
    query_search(query_string, callback) {
      var restaurants = this.serach_option;
      var results = [];
      // 影人匹配
      for (var i = 0; i < restaurants.length; i++) {
        if (restaurants[i].value.includes(query_string.trim().toLowerCase())) {
          results.push(restaurants[i]);
        }
      }
      callback(results);
    },
    // 匹配项点击
    handle_select(item) {
      switch (this.select) {
        case "1":
          // 影人搜索
          this.$router.push("/directors?=" + item.value);
          this.$store.commit("set_current_figure", item.value);
          break;
        case "2":
          // 影片搜索
          this.$router.push("/video?id=" + item.id);
          this.$store.commit("set_current_figure", item.director);
          break;
        case "3":
          // 文章搜索
          this.$router.push("/essays?id=" + item.id);
          this.$store.commit("set_current_figure", item.writer);
          break;
        case "4":
          // 文献搜索
          this.$router.push("/literature?id=" + item.id);
          this.$store.commit("set_current_figure", item.label);
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });
      }
    },
    toggleSearch() {
      this.isSearchVisible = !this.isSearchVisible;
    },
  },
  created() {
    this.$store.commit("toggleCollapse");
    //用户数据
    if (this.$store.state.login_state) {
      this.axios
        .post("https://api.movie.yingshinet.com/showinfo", {
          name: this.$store.state.user_name,
        })
        .then((response) => {
          this.user_id = response.data.id;
          this.change_infor.email = response.data.email;
          this.change_infor.true_name = response.data.true_name;
          this.change_infor.sex = response.data.sex;
          this.change_infor.phone = response.data.phone;
          this.change_infor.idcard = response.data.idcard;
          this.change_infor.idcertificates = response.data.idcertificates;
        });
    }
    // 影人数据
    this.axios.get(this.path + "/figures").then((response) => {
      const fig_count = response.data.f_count;
      for (var i = 0; i < fig_count; i++) {
        this.figures.push(response.data.f_data[i]);
        this.figure_names.push({ value: response.data.f_data[i].f_name });
      }
      this.figures_serach = this.figures;
      this.fig_response = response.data;
      // this.figure_count = response.data.f_count;
      this.serach_option = this.figure_names;
    });
    // 年谱数据
    // this.axios.get(this.path + "/chronicles").then((response) => {
    //   this.chronicle_count = response.data.c_count;
    // });
    // 影片数据
    this.axios.get(this.path + "/movies").then((response) => {
      this.movie_count = response.data.m_count;
      for (var i = 0; i < this.movie_count; i++) {
        // value必须，不然搜索框不显示
        this.movies.push({
          value: response.data.m_data[i].m_name,
          id: response.data.m_data[i].mid,
          director: response.data.m_data[i].m_director,
        });
      }
    });
    // 影人文章数据
    this.axios.get(this.path + "/essays/part").then((response) => {
      this.essay_count = response.data.e_count;
      for (var i = 0; i < this.essay_count; i++) {
        this.essays.push({
          value: response.data.e_data[i].title,
          id: response.data.e_data[i].wid,
          writer: response.data.e_data[i].writer,
        });
      }
    });

    // 相关文献数据
    this.axios.get(this.path + "/articles/part").then((response) => {
      this.literature_count = response.data.a_count;
      for (var i = 0; i < this.literature_count; i++) {
        this.articles.push({
          value: response.data.a_data[i].title,
          id: response.data.a_data[i].id,
          label: response.data.a_data[i].label,
        });
      }
    });
  },
};
</script>

<style>
#app>div[data-v-23246054] {
  width: 100%;
}

.header_new>.header_height_new.el-menu-item {
  padding: 0 0.6vw;
  height: 80px;
}

.input_style {
  z-index: 2;
  width: 17vw;
  right: 3vw;
  top: 12vh;
  border-radius: 1vw;
  box-shadow: 4px 4px 10px #aaaaaa;
}

.input_style_hidden {
  display: none;
}

.el-submenu__title {
  padding: 1.5vh 1.0vw !important;
}

.el-submenu__icon-arrow {
  display: none !important;
}

/* .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #000000 !important;
} */

.el-menu-item {
  border-bottom: none !important;
  text-decoration: none !important;
  height: 50px !important;
  margin-top: 0 !important;
  font-family: SimSun, Times, serif !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
  border-bottom: none !important;
  margin-top: 10px !important;
  text-decoration: none !important;
  line-height: 40px !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  font-size: 1.2vw;
  font-weight: bold;
  height: fit-content;
  border-bottom: none !important;
  text-decoration: none !important;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  font-size: 1.2vw;
  font-weight: bold;
  border-bottom: none !important;
  text-decoration: none !important;
}

.el-menu--popup {
  min-width: 11vw !important;
}

.input_style .el-input__inner:focus {
  border-color: #e2a253;
}

.select_style .el-input__inner:focus {
  border-color: transparent;
}

.el-autocomplete {
  position: absolute !important;
  top: 12vh;
  left: 0;
  z-index: 1000;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #F5F7FA !important;
  color: #e2a253 !important;
}

body {
  margin: 0px;
  height: auto;
}

.sao_style:hover {
  z-index: 1;
  transform: scale(1.4, 1.4);
  transition: 1s transform;
  cursor: pointer;
  box-shadow: 8px 8px 20px #aaaaaa;
}

.mouse_over_user:hover {
  cursor: pointer;
}

.elebutton .el-backtop {
  right: 22px;
  bottom: 70px;
  width: 60px;
  height: 60px;
  font-size: 28px;
}

.menu {
  position: relative;
}

.menu .el-submenu__title {
  font-size: 1.1vw;
  height: 30px;
  color: rgb(233, 233, 233);
}

.menu .el-menu-item {
  font-size: 1.1vw;
  line-height: 0;
}

.menu .el-submenu {
  left: 0.5vw;
}

.menu .reandlog {
  float: right;
}

.contact {
  position: fixed;
  right: 40px;
  bottom: 90px;
  height: 45px;
  width: 45px;
  box-shadow: 3px 3px 10px #aaaaaa;
  background-color: #f7f7f7fa;
}

.card_shadow {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.el-footer {
  color: #3C3C3C;
  font-size: 16px;
  text-align: center;
}

.el-container {
  min-height: 100vh;
  justify-content: space-between;
  background-color: #3C3C3C;
}

.top_icon_style {
  margin: 0 4px;
  font-size: 30px;
  color: #e2a253;
}

.text_collapse {
  font-size: 4px;
  line-height: 2vw;
  text-align: left;
}

.text_style {
  font-size: 0.9vw;
  line-height: 1.6vw;
  text-align: left;
}

.el-dropdown-link {
  cursor: pointer;
  width: 1.8vw;
}

.foot_middle {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 38px;
}

.foot_middle_collapse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
}
</style>
