import Vue, { KeepAlive } from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Video from "../views/Video.vue";
import Video_index from "../views/Video_index.vue";
import Video_index_graph from "../views/Video_index_graph.vue";
// import Article from '../views/Article.vue'
import Content_book from "../views/Content_book.vue";
import Content_journal from "../views/Content_journal.vue";
import Content_references from "../views/Content_references.vue";
import Content_website from "../views/Content_website.vue";
import Content_letters from "../views/Content_letters.vue";
import Directors from "../views/directors.vue";
import DirectorsWorld from "../views/directors_world.vue";
import DigitalLife from "../views/DigitalLife.vue";
import Literature from "../views/Literature.vue";
import Essays from "../views/Essays.vue";
import EssaysIndex from "../views/EssaysIndex";
import figurearticleIndex from "../views/figurearticleIndex";
import Chronicle from "../views/Chronicle.vue";
import ChronicleList from "../views/ChronicleList.vue";
import Company from "../views/Company.vue";
import Home from "../views/Home.vue";
import Statistics from "../views/Statistics.vue";
import RegisterAuthorize from "../views/RegisterAuthorize.vue";
import Introduction from "../views/Introduction.vue";
import ArticleIndex from "../views/ArticleIndex.vue";
import Filmanalysis from "../views/Filmanalysis.vue";
import FilmVisualization from "../views/FilmVisualization.vue";
import LengthTable from "../views/LengthTable.vue";
import CompilationGuide from "../views/CompilationGuide.vue";
import dialog from "../views/dialog.vue";
import Montage from "../views/Montage.vue";
import postSearch from "../views/postSearch.vue";
import titleSearch from "../views/TitleSearch.vue";
import AnnualEvent from "../views/AnnualEvent.vue";
import occupation from "../views/occupation.vue";
import movie_France from "../views/movie_France.vue";
import HKmovie from "../views/HKmovie.vue";
import post from "../views/post.vue";
import title from "../views/Title.vue";
import PostTitle from "../views/PostTitle.vue";
import Content_selectbooks from "../views/Content_selectbooks.vue";
import Simple from "../views/Simple.vue";
import PersonalPage from "../views/PersonalPage.vue";
import CertificatePage from "../views/CertificatePage.vue";
import Guide from "../views/Guide.vue";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/articleIndex",
    name: "ArticleIndex",
    component: ArticleIndex,
  },
  {
    path: "/Statistics",
    name: "Statistics",
    component: Statistics,
  },
  {
    path: "/Filmanalysis",
    name: "Filmanalysis",
    component: Filmanalysis,
  },
  {
    path: "/FilmVisualization",
    name: "FilmVisualization",
    component: FilmVisualization,
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: Introduction,
  },
  {
    path: "/lengthTable",
    name: "LengthTable",
    component: LengthTable,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      keepAlive:true,
    },
  },

  {
    path: "/Simple",
    name: "Simple",
    component: Simple,
    meta: {
      hide: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hide: true,
    },
  },
  {
    path: "/chronicle",
    name: "Chronicle",
    component: Chronicle,
  },
  {
    path: "/ChronicleList",
    name: "ChronicleList",
    component: ChronicleList,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
  },
  {
    path: "/video_index",
    name: "Video_index",
    component: Video_index,
  },
  {
    path: "/video_index_graph",
    name: "Video_index_graph",
    component: Video_index_graph,
  },
  {
    path: "/essays",
    name: "Essays",
    component: Essays,
  },
  {
    path: "/essaysindex",
    name: "EssaysIndex",
    component: EssaysIndex,
  },
  {
    path: "/figurearticleIndex",
    name: "figurearticleIndex",
    component: figurearticleIndex,
  },
  {
    path: "/occupation",
    name: "occupation",
    component: occupation,
  },
  {
    path: "/Content_book",
    name: "Content_book",
    component: Content_book,
  },
  {
    path: "/Content_journal",
    name: "Content_journal",
    component: Content_journal,
  },
  {
    path: "/Content_references",
    name: "Content_references",
    component: Content_references,
  },
  {
    path: "/Content_website",
    name: "Content_website",
    component: Content_website,
  },
  {
    path: "/Content_letters",
    name: "Content_letters",
    component: Content_letters,
  },
  {
    path: "/DigitalLife",
    name: "DigitalLife",
    component: DigitalLife,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      hide: true,
    },
  },
  {
    path: "/registerAuthorize",
    name: "RegisterAuthorize",
    component: RegisterAuthorize,
  },
  {
    path: "/directors",
    name: "Directors",
    component: Directors,
  },
  {
    path: "/directors_world",
    name: "DirectorsWorld",
    component: DirectorsWorld,
  },
  {
    path: "/literature",
    name: "Literature",
    component: Literature,
  },
  {
    path: "/CompilationGuide",
    name: "CompilationGuide",
    component: CompilationGuide,
  },
  {
    path: "/dialog",
    name: "dialog",
    component: dialog,
  },
  {
    path: "/Montage",
    name: "Montage",
    component: Montage,
  },
  {
    path: "/postSearch",
    name: "postSearch",
    component: postSearch,
  },
  {
    path: "/titleSearch",
    name: "titleSearch",
    component: titleSearch,
  },
  {
    path: "/AnnualEvent",
    name: "AnnualEvent",
    component: AnnualEvent,
  },
  {
    path: "/movie_France",
    name: "movie_France",
    component: movie_France,
  },
  {
    path: "/HKmovie",
    name: "HKmovie",
    component: HKmovie,
  },
  {
    path: "/post",
    name: "post",
    component: post,
  },
  {
    path: "/title",
    name: "title",
    component: title,
  },
  {
    path: "/PostTitle",
    name: "PostTitle",
    component: PostTitle,
  },
  {
    path: "/Content_selectbooks",
    name: "Content_selectbooks",
    component: Content_selectbooks,
  },
  {
    path: "/PersonalPage",
    name: "PersonalPage",
    component: PersonalPage,
  },
  {
    path: "/CertificatePage",
    name: "CertificatePage",
    component: CertificatePage,
  },
  {
    path: "/guide",
    name: "Guide",
    component: Guide,
  },
  {
    path: "/guideV2",
    name: "GuideV2",
    component: Guide,
  },
];

const router = new VueRouter({
  routes,
});
export default router;
