import { render, staticRenderFns } from "./ChronicleList.vue?vue&type=template&id=c79d2162&scoped=true"
import script from "./ChronicleList.vue?vue&type=script&lang=js"
export * from "./ChronicleList.vue?vue&type=script&lang=js"
import style0 from "./ChronicleList.vue?vue&type=style&index=0&id=c79d2162&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79d2162",
  null
  
)

export default component.exports