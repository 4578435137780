<template>
    <!-- <div v-if="this.$store.state.is_administrator"> -->
    <!-- <div v-if="this.$store.state.is_summerschoolstudent"> -->
    <div v-if="this.$store.state.login_state">
        <div style="margin-left:5%;margin-right:5%">
            <el-container style="width: 100%;">
                <el-main>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="article_count"
                        :page-size="page_size"
                        align="center"
                        style="margin-top:20px;margin-bottom:20px"
                        @current-change="page_change"
                        :current-page.sync="currentPage"
                    ></el-pagination>
                    
                    <el-container direction="vertical">
                        <el-row :gutter="20">
                            <el-col :span="3" v-for="(data, i) in paginatedData" :key="data.id" class="mouse_hover">
                                <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                                    <el-image :src="getImageUrl(data.filepath)" class="image" fit="cover"
                                        @click="showImage(data.filepath)" />
                                    <div class="bottom clearfix">
                                        <div style="font-size: 15px;color: #999;text-align:center">
                                            {{ getFileName(data.filepath).slice(0, 15) }}
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </el-container>
                    
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="article_count"
                        :page-size="page_size"
                        align="center"
                        style="margin-top:20px;margin-bottom:20px"
                        @current-change="page_change"
                        :current-page.sync="currentPage"
                    ></el-pagination>
                </el-main>
            </el-container>

            <!-- 放大图片的对话框 -->
        <el-dialog 
            :visible.sync="dialogVisible" 
            :width="dialogWidth" 
            :before-close="handleClose" 
            append-to-body
            custom-class="image-dialog"
        >
            <div class="image-container" :style="containerStyle">
                <el-image 
                    ref="rotatedImage"
                    :src="currentImage" 
                    fit="contain" 
                    :style="{ 
                        transform: `rotate(${rotationDegrees}deg) scale(${zoomLevel})`,
                        maxWidth: '100%',
                        maxHeight: '100%',
                        transition: 'transform 0.3s ease'
                    }" 
                    @load="handleImageLoad"
                />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button-group>
                    <el-button @click="zoomOut" icon="el-icon-zoom-out" title="缩小"></el-button>
                    <el-button @click="resetZoom" icon="el-icon-circle-close" title="重置大小"></el-button>
                    <el-button @click="zoomIn" icon="el-icon-zoom-in" title="放大"></el-button>
                </el-button-group>
                <el-button-group>
                    <el-button @click="rotateImage(-90)" icon="el-icon-refresh-left" title="向左旋转"></el-button>
                    <el-button @click="resetRotation" icon="el-icon-refresh" title="重置旋转"></el-button>
                    <el-button @click="rotateImage(90)" icon="el-icon-refresh-right" title="向右旋转"></el-button>
                </el-button-group>
                <el-button @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        </div>
    </div>
    <div v-else>
        <el-alert type="error" title="无权限访问" :closable="false"></el-alert>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            allData: [], // 存储所有数据
            tableData: [], // 当前页数据
            article_count: 0,
            page_size: 32,
            currentPage: 1,
            loading: false,
            error: null,
            dialogVisible: false,
            currentImage: '',
            rotationDegrees: 0, // 添加旋转角度状态
            dialogWidth: '80%', // 初始宽度
            originalWidth: 0,   // 图片原始宽度
            originalHeight: 0, // 图片原始高度
            containerStyle: {} , // 容器样式
            zoomLevel: 1, // 缩放级别
            minZoom: 0.5, // 最小缩放级别
            maxZoom: 3,  // 最大缩放级别
            zoomStep: 0.1 // 缩放步长
        }
    },
    computed: {
        paginatedData() {
            // 计算当前页应该显示的数据
            const start = (this.currentPage - 1) * this.page_size;
            const end = start + this.page_size;
            return this.allData.slice(start, end);
        }
    },
    created() {
        this.fetchAllData();
    },
    methods: {
        async fetchAllData() {
            try {
                this.loading = true;
                const response = await axios.get('https://api.movie.yingshinet.com/collection');
                this.allData = response.data.c_data;
                this.article_count = response.data.c_count;
                this.updatePaginatedData();
            } catch (error) {
                this.error = '加载数据失败: ' + error.message;
                console.error('获取数据失败:', error);
            } finally {
                this.loading = false;
            }
        },
        page_change(page) {
            this.currentPage = page;
            this.updatePaginatedData();
        },
        updatePaginatedData() {
            // 根据当前页码更新显示的数据
            const start = (this.currentPage - 1) * this.page_size;
            const end = start + this.page_size;
            this.tableData = this.allData.slice(start, end);
        },
        getImageUrl(filepath) {
            return "https://assets.yingshinet.com/" + filepath.replace(/\\/g, '/');
        },
        getFileName(filepath) {
            return filepath.replace(/\\/g, '/').split('/').pop().replace('.jpg', '');
        },
        // 放大图片
        zoomIn() {
            if (this.zoomLevel < this.maxZoom) {
                this.zoomLevel = Math.min(this.zoomLevel + this.zoomStep, this.maxZoom);
            }
        },
        
        // 缩小图片
        zoomOut() {
            if (this.zoomLevel > this.minZoom) {
                this.zoomLevel = Math.max(this.zoomLevel - this.zoomStep, this.minZoom);
            }
        },
        
        // 重置缩放
        resetZoom() {
            this.zoomLevel = 1;
        },
        showImage(filepath) {
            this.currentImage = this.getImageUrl(filepath);
            this.dialogVisible = true;
            this.rotationDegrees = 0; // 每次打开对话框时重置旋转角度
            this.zoomLevel = 1; // 重置缩放级别
            this.dialogWidth = '80%'; // 重置对话框宽度
            this.containerStyle = {}; // 重置容器样式
        },
        handleImageLoad() {
            // 获取图片原始尺寸
            const img = this.$refs.rotatedImage.$el.querySelector('img');
            if (img) {
                this.originalWidth = img.naturalWidth;
                this.originalHeight = img.naturalHeight;
                this.adjustDialogSize();
            }
        },
        
        rotateImage(degrees) {
            this.rotationDegrees += degrees;
            // 确保角度在0-360度之间
            this.rotationDegrees = this.rotationDegrees % 360;
            this.adjustDialogSize();
        },
        
        adjustDialogSize() {
            // 计算旋转后的尺寸
            const isRotated = this.rotationDegrees % 180 !== 0;
            const width = isRotated ? this.originalHeight : this.originalWidth;
            const height = isRotated ? this.originalWidth : this.originalHeight;
            
            // 计算宽高比
            const aspectRatio = width / height;
            
            // 根据宽高比调整对话框尺寸
            if (aspectRatio > 1) {
                // 宽图
                this.dialogWidth = '80%';
                this.containerStyle = {
                    height: `calc(80vw / ${aspectRatio})`,
                    maxHeight: '80vh'
                };
            } else {
                // 高图或方图
                this.dialogWidth = `${Math.min(80, 80 * aspectRatio)}%`;
                this.containerStyle = {
                    height: '80vh',
                    maxWidth: '80vw'
                };
            }
            
            // 强制对话框重新计算位置
            this.$nextTick(() => {
                if (this.$refs.rotatedImage && this.$refs.rotatedImage.updatePopper) {
                    this.$refs.rotatedImage.updatePopper();
                }
            });
        },
        resetRotation() {
            this.rotationDegrees = 0;
            this.adjustDialogSize();
        },
        handleClose(done) {
            this.dialogVisible = false;
            done();
        }
    }
}
</script>
  
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e2a253 !important;
    color: #fff;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #e2a253 !important;
}

.el-dialog__headerbtn .el-dialog__close:hover {
    color: #e2a253 !important;
}
</style>
  
<style scoped>
.mouse_hover:hover {
    cursor: pointer;
}

.image {
    width: 120px;
    height: 140px;
    display: block;
}

.card {
    width: 120px;
    height: 160px;
    margin-bottom: 20px;
}

.bottom {
    margin-top: 0px;
    line-height: 12px;
    padding-top: 5px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

.image-container {
    overflow-y: auto;
    display: flex;
    justify-content: center;
}
</style>